import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/offer.module.css"
import SEO from "../../components/seo"

const Beceptum = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot Beceptum" />
      <StyledHero img={data.beceptumBcg.childImageSharp.fluid} />
      <section className={styles.offer}>
        <Title title="Leistungsangebot" subtitle="Beceptum" />
        <div className={styles.offerCenter}>
          <header>
            <a
              href="https://www.beceptum.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/beceptum.png"
                alt="Bececptum International Logo"
              />
            </a>
            <h4>Pro Bono Leistungsangebot Beceptum International GmbH</h4>
            <p>
              Unsere Angebote beruhen auf einer kurzen und kurzfristigen
              Unterstützung. Bis zum 30.06.2020 sind die Angebote für Sie
              kostenfrei und vollständig unverbindlich.
            </p>
          </header>
          <article className={styles.offerText}>
            <h4>Quick check und Coaching für Investoren Briefings</h4>
            <p>
              Wenn Sie nach Investoren suchen, um wichtige Investitionen auch in
              der Krise tätigen zu können oder gerade in Finanzierungsrunden
              stecken, sind drei Dinge wichtig:
            </p>
            <ul>
              <li>
                Die qualitativ und inhaltlich überzeugendes Unterlagenpaket
              </li>
              <li>
                Die Bewertung und Berücksichtigung der Krise in Ihrem Business
                Plan
              </li>
              <li>Ihre Krisen und Nachkrisenstrategie</li>
            </ul>
            <p>
              Wir bieten Ihnen einen Schnellcheck Ihrer Unterlagen an und geben
              Ihnen wertvolle Tipps zur weiteren Strategie und zur
              Vervollständigung Ihrer Unterlagen.
            </p>
          </article>
          <article className={styles.offerText}>
            <h4>Quick check und Coaching für Geschäftspläne</h4>
            <p>
              <p>
                Für den Großteil der staatlichen finanziellen Hilfsmaßnahmen
                brauchen Sie Ihre Hausbank im Boot. Diese muss Ihre
                Geschäftspläne bewerten und letztendlich bereit sein, mit
                mindestens 10% Kreditrisiko einen Kredit zu gewähren, um auf die
                staatlichen Mittel zugreifen zu können.{" "}
              </p>
              <p>
                Wir bieten Ihnen einen Schnellcheck Ihrer Unterlagen an und
                geben Ihnen Empfehlungen, an welchen Stellen und welchen
                Argumenten Sie sich noch besser aufstellen können. Gerne gehen
                wir auf ihre speziellen Fragen ein.
              </p>
            </p>
          </article>
          <article className={styles.offerText}>
            <h4>
              Coaching und Brainstorming Sessions: Eco-Systeme und strategische
              Partnerschaften in der Krise als Wachstumstreiber nach der Krise
            </h4>
            <p>
              „Gemeinsam stark“ ist nicht nur das Motto der Initiative
              VsCovidUnited, sondern auch ein Überlebenskriterium für das
              Geschäft in und nach der Krise.
            </p>
            <p>
              Wir diskutieren gerne mit Ihnen Ihr Geschäftsmodell, stellen Ihnen
              die Potenziale von Eco-Systemen und strategischen Partnerschaften
              dar und überlegen gemeinsam mit Ihnen konkrete Ansätze.
            </p>
          </article>
          <article className={styles.offerText}>
            <h4>
              Coaching und Brainstorming Sessions: Politische
              Interessensvertretung in Zeiten der Krise
            </h4>
            <p>
              Die Krise zeigt eines sehr deutlich: Wir sind in der Versorgung
              und in den Wertschöpfungsketten in höchstem Maße abhängig. Eine
              wesentliche Konsequenz wird die Anpassung von internationalen
              Produktions- und Zulieferketten sein. Um nach der Krise
              schnellstmöglich und gut gerüstet durchstarten zu können, müssen
              frühzeitig die Weichen gestellt werden.
            </p>
            <p>
              Diskutieren Sie mit uns ihr internationales Set-up und Ihre
              Überlegungen für eine zukünftige Aufstellung. Wie wir in diesen
              turbulenten Zeiten verstärkt wahrnehmen können, erlangen
              geopolitischen Zusammenhänge in einer globalisierten Wirtschaft
              eine verstärkte Bedeutung und insofern spielt die begleitende
              Unterstützung Ihrer Themen durch die Politik eine zunehmende
              Rolle. Wir erarbeiten mit Ihnen Ansätze für eine zielgerichtete
              politische Flankierung Ihrer internationalen Projekte und
              Kampagnen.
            </p>
          </article>
          <footer>
            <a
              href="mailto:kontakt@vscovidunited.de"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Angebot anfordern
            </a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    beceptumBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Beceptum
